import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { Subject, filter, merge, switchMap, take, takeUntil } from 'rxjs'
import { NavigationEnd, Router } from '@angular/router'
import { StoreService, User, UiService, SidebarState, WindowRefService, UserService, ViewportType } from 'shared-lib'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { SidebarComponent } from '../sidebar/sidebar.component'
import { GuidedTourService, TourStep } from 'ngx-guided-tour'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'

export interface ShoutlyTourStep extends TourStep {
  orgType: string[]
}

const ONBOARD_TOUR_STEPS: ShoutlyTourStep[] = [
  {
    selector: '.sidebar-menu-item-collaborations',
    title: _('Manage collaborations'),
    content: _('Create new collaborations, Cancel, finish, extend deadlines, etc'),
    useHighlightPadding: false,
    orientation: 'bottom-left',
    orgType: ['gigger', 'employer']
  },
  {
    selector: '.sidebar-menu-item-self-invoices',
    title: _('Manage payments'),
    content: _('Review transactions, generate invoices and make payments'),
    useHighlightPadding: false,
    orientation: 'top-left',
    orgType: ['gigger']
  },
  {
    selector: '.sidebar-menu-item-billing-bills',
    title: _('Manage billing'),
    content: _('Generate, list or cancel your transactions and bills'),
    useHighlightPadding: false,
    orientation: 'top-left',
    orgType: ['employer']
  }
]

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>()
  public viewportType: ViewportType = ViewportType.Mobile
  public sidebarState: SidebarState = SidebarState.Opened
  public user: User = null
  public overlayRef: OverlayRef

  constructor (
    public storeService: StoreService,
    private windowRef: WindowRefService,
    private router: Router,
    private uiService: UiService,
    private overlay: Overlay,
    private tourService: GuidedTourService,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) {
    const positionStrategy = this.overlay.position()
      .global()
      .left('0')
      .top('0')

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      height: '100%'
    })

    merge(
      this.overlayRef.backdropClick(),
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd)
        )
    )
      .subscribe(() => {
        this.closeOverlaySidebar()
      })
  }

  private initHubSpotConversations () {
    const window_ = this.windowRef.nativeWindow
    if (window_.HubSpotConversations && window_.HubSpotConversations.widget) {
      window_.HubSpotConversations.widget.load()
    }
  }

  ngOnInit () {
    this.initHubSpotConversations()

    this.uiService.uiChange$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.viewportType = data.viewportType
        this.adjustSidebarState(data.viewportType)
        this.cdr.detectChanges()
      })

    // Show guide if user.guide_seen is true
    this.storeService.user$
      .pipe(
        filter(user => !user.guide_seen),
        switchMap(() => this.storeService.organization$),
        filter(org => !!org),
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe({
        next: org => {
          this.initGuideTour(org.type)
        }
      })
  }

  ngOnDestroy (): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private initGuideTour (orgType: 'gigger' | 'employer') {
    const steps = this.getGuideTourByOrgType(ONBOARD_TOUR_STEPS, orgType)
    const maxChecks = 50 // This corresponds to 5 seconds if using a 100ms interval
    let currentCheck = 0
    const stepSelectors = steps.map(step => step.selector)

    const checkElement = setInterval(() => {
      if (this.anyElementExists(stepSelectors) || currentCheck >= maxChecks) {
        clearInterval(checkElement)
        if (this.anyElementExists(stepSelectors)) {
          this.startTour(steps, orgType)
        } else {
          console.error('Failed to find the element after maximum attempts. Tour not started.')
        }
      }
      currentCheck++
    }, 100)
  }

  private anyElementExists (selectors: string[]): boolean {
    return selectors.some(selector => !!document.querySelector(selector))
  }

  private getGuideTourByOrgType (tourSteps: ShoutlyTourStep[], orgType: 'gigger' | 'employer'): ShoutlyTourStep[] {
    const res: ShoutlyTourStep[] = []

    tourSteps.forEach((step: ShoutlyTourStep) => {
      if (step.orgType.find(element => element === orgType)) {
        res.push(step)
      }
    })

    return res
  }

  private startTour (steps: ShoutlyTourStep[], orgType: 'gigger' | 'employer') {
    const filteredSteps = this.getGuideTourByOrgType(steps, orgType)

    const _this = this

    this.openSidebar()

    return this.tourService.startTour({
      tourId: 'onboard',
      steps: filteredSteps,
      completeCallback () {
        _this.onTourFinish()
      },
      skipCallback (stepSkippedOn) {
        _this.onTourFinish()
      }
    })
  }

  private onTourFinish(){
    if(this.viewportType === ViewportType.Mobile){
      this.closeSidebar()
    }

    if(this.viewportType === ViewportType.Tablet){
      this.collapseSidebar()
    }

    this.setUserGuideSeen()
  }

  private setUserGuideSeen () {
    this.userService.setUserGuideSeen()
      .subscribe()
  }

  private adjustSidebarState (viewportType: ViewportType): void {
    switch (viewportType) {
      case ViewportType.Mobile:
        this.sidebarState = SidebarState.Closed
        break
      case ViewportType.Tablet:
        this.sidebarState = SidebarState.Collapsed
        break
      case ViewportType.Desktop:
        this.sidebarState = SidebarState.Opened
        break
      default:
        break
    }
  }

  public toggleSidebar (viewportType: ViewportType): void {
    switch (viewportType) {
      case ViewportType.Mobile:
        this.toggleOverlaySidebar()
        break
      default:
        this.toggleNormalSidebar()
        break
    }
  }

  private closeSidebar () {
    this.sidebarState = SidebarState.Closed
  }

  private collapseSidebar () {
    this.sidebarState = SidebarState.Collapsed
  }

  private openSidebar () {
    this.sidebarState = SidebarState.Opened
  }

  private openOverlaySidebar (): void {
    const sidebarPortal = new ComponentPortal(SidebarComponent)
    const componentRef = this.overlayRef.attach(sidebarPortal)

    componentRef.instance.isHandset = this.viewportType === ViewportType.Mobile

    componentRef.instance.toggle
      .subscribe(() => {
        this.closeOverlaySidebar()
      })
  }

  private closeOverlaySidebar (): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach()
    }
  }

  private toggleOverlaySidebar (): void {
    if (this.overlayRef.hasAttached()) {
      this.closeOverlaySidebar()
    } else {
      this.openOverlaySidebar()
    }
  }

  private toggleNormalSidebar (): void {
    if (this.sidebarState === SidebarState.Opened) {
      this.collapseSidebar()
    } else {
      this.openSidebar()
    }
  }
}
